import React, { useContext, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../hooks/useAuth'
import {
    BrowserRouter as Router,
    useParams
  } from "react-router-dom";
import { AppContext } from '../../contexts/App';

import { SignUp } from './SignUp';
import posthog from 'posthog-js';
import { getProducts } from '../../contexts/getProducts';
import { createCheckoutSession } from '../../util/createCheckoutSession';
import { Progress } from '@chakra-ui/react'
import { Button, ButtonGroup } from '@chakra-ui/react'
import { Divider } from '@chakra-ui/react'


const Wrapper = styled.div`
    background: rgba(0, 0, 0, 0.33);
    /* background: white; */
    backdrop-filter: blur(6px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    min-width: 500px;
    max-width: 100vw;
    min-height: calc((var(--vh, 1vh)*100));
    max-height: calc((var(--vh, 1vh)*100));
    overflow: scroll;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 768px) {
        min-width: unset;
    }

    & > div {
        box-sizing: border-box;
        border-radius: 6px;
        background: white;
        padding: 3rem;
        justify-content: flex-start;
        width: 500px;
        max-width: 100%;
        display: flex;
        max-width: 100vw;
        flex-direction: column;
        align-items: flex;
        max-height: 100vh;
        overflow-y:scroll;

        @media only screen and (max-width: 768px) {
            width: 100%;
            overflow: scroll;
            padding: 3rem;
            height: 100vh;
            border-radius: 0px;
        }

        h1 {
            background: white;
            margin-right: 1rem;
            color: #495057;
            font-size: 2rem;
            font-weight: 700;
            margin-bottom: 2;
            color: gray;
        };
    }
`

const ModelLineItem = styled.div`
    cursor: select;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 100%;
    color: #ced4da;
    h2 {
        background: white;
        margin-right: 1rem;
        font-size: 1.2rem;
        font-weight: 800;
        color: gray;
    };
    h3 {
        margin-right: 1rem;
        font-size: 1rem;
        font-weight: 600;
        color: gray;
        padding-bottom: .5rem;
    };
    p {
        line-heigh: 1;
        font-size: 1rem;
        font-weight: 500;
        color: gray;
    }
`

const UpgradeButton = styled.li`
    cursor: pointer;
    height: 2rem;
    width: 30%;
    justify-content: center;
    align-content: center;
    padding: .5rem;
    border-radius: 4px;
    margin-bottom: 1rem;
    background: #ced4da;
    h3 {
        font-size: 14px;
        margin: auto;
        font-weight: 800;
        color: #495057;
        text-align: center;
    }
    &:hover {
        background: rgb(2, 115, 245, 0.05);
        background: #a5d8ff;
    }
`

const Close = styled.li`
    cursor: pointer;
    height: 2rem;
    width: 30%;
    justify-content: center;
    align-content: center;
    padding: .5rem;
    border-radius: 4px;
    margin-bottom: 1rem;
    background: #ced4da;
    h3 {
        font-size: 14px;
        margin: auto;
        font-weight: 800;
        color: #495057;
        text-align: center;
    }
    &:hover {
        background: rgb(2, 115, 245, 0.05);
        background: #a5d8ff;
    }
`

// Manually set products
const products = [
    {
        name: 'Current Map Premium',
        description: 'Monthly subscription providing access to premium ocean current forecast regions. Account upgrade may take a few hours to process after payment.',
        price: 10000,  // $100.00
        stripeURL: 'https://buy.stripe.com/4gwbKfgBbd7b2M84gl'
    },
    // ...more products
]

const Modal = (props) => {
    const { user, isAuthenticated } = useAuth()
    const { showModal, toggleModal } = useContext(AppContext);
    const { currentUser, toggle } = props;
    const [loading, setLoading] = useState();
    const [progress, setProgress ] = useState(0)
    const currentPlans = currentUser?.plans || [];
    console.log(currentPlans)

    const normalizePrice = (price) =>{
        const output = `$${price/100}.00`
        return output
    }


    return (
        <>
        <Wrapper>
            <div>
                <h1>
                    Premium Packages
                </h1>
                <Divider />
                {/* add scrolling */}
                {products && products.map((product) => {
                    return (
                        <>
                        <div
                            key={product.name}
                        >
                        <ModelLineItem>
                            <p>
                                <strong>
                                    {`${product.name || ''} `}
                                </strong>
                            <br />
                            {`${product.description || ''}`}
                            <br />
                            {`${normalizePrice(product.price) || ''}`}
                            </p>
                        </ModelLineItem>
                            {!loading ? (
                                <>
                                {!currentPlans.includes(product.name) ? (
                                    <Button
                                        size='sm'
                                        onClick={() => window.location.href = product.stripeURL}
                                        sx={{
                                            color: 'white',
                                            mb: 3,
                                        }}
                                        colorScheme='blue'
                                    >
                                        Continue
                                    </Button>
                                ) : (
                                    <Button
                                        isDisabled
                                        size='sm'
                                        colorScheme='green'
                                    >
                                        Active
                                    </Button>
                                )}
                                </>
                            ) : (
                                <Progress colorScheme='blue' size='xs' isIndeterminate />
                            )}
                        </div>
                        </>
                    )
                })}
                <Button
                    onClick={() =>{
                        toggle(false)
                    }}
                    size='md'
                    colorScheme='gray'
                    sx={{
                        p:2,
                        mt:3
                    }}
                >
                        Close
                </Button>
            </div>
        </Wrapper>
        </>
    )   
}

export default Modal;