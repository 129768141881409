import { useCallback, useState, useContext } from 'react'
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth'
import { AppContext } from '../../contexts/App';

import styled from 'styled-components'
import Logo from '../../img/current_lab.svg'
import posthog from 'posthog-js';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	& > a {
		/* background: red; */
	}
	img {
		width: 128px;
	}
	form {
		display: grid;
		grid-gap: 0.66rem;
		width: 100%;
		div {
			display: inline-flex;
			flex-flow: column-reverse;
			/* align-items: flex-start */
			label {
				transition: opacity 0.33s;
				opacity: 0;
				height: 0;
			}

			input:not(:placeholder-shown) + label {
				opacity: 1;
				height: auto;
			}
		}
		label {
			padding-left: 0.66rem;
			font-size: 12px;
			transform: translateY(50%);
			background: white;
			padding: 0.33rem;
			margin-left: 0.33rem;
			align-self: flex-start;
			/* margin-bottom: 0.33rem; */
		}
		fieldset {
			all: unset;
			display: grid;
			grid-gap: 2rem;
			/* grid-template-columns: minmax(auto, 50%) minmax(auto, 50%); */
			grid-template-columns: repeat(2, calc(50% - 1rem));
		}
		input {
			all: unset;
			font-size: 14px;
			border: 1px solid gray;
			padding: 0.8rem 0.66rem;
			/* background: #f5f5f5; */
			border-radius: 6px;
			width: 100%;
    		box-sizing: border-box;
		}
		input:-webkit-autofill {
			-webkit-box-shadow:0 0 0 50px rgba(2, 114, 242, 0.01) inset; /* Change the color to your own background color */
			-webkit-text-fill-color: #333;
		}
		select {
			all: unset;
			padding: 0.33rem 0rem;
			border-bottom: 1px solid gray;
		}
		button {
			all: unset;
			background: #0264d4;
			padding: 1rem 0.66rem;
			cursor: pointer;
			border-radius: 6px;
			color: white;
			text-align: center;
			margin-top: 0.66rem;
		}
	}
	p {
		font-size: 14px;
		text-align: center;
		b {
			cursor: pointer;
		}
	}

`

const SignUpForm = ({newUser, onSubmit, onForgotPassword, setEmailInput, }) => {
	
	return (
		<form
			onSubmit={onSubmit}
		>
			<div>
				<input id="email" name="email" placeholder="Email" type="email" 
					onChange={e => setEmailInput(e.target.value)} />
				<label htmlFor="email">Email Address</label>
			</div>
			<div>
				<input id="password" name="password" placeholder="Password" type="password" />
				<label htmlFor="password">Password</label>
			</div>
			{newUser &&
			<>
				<div>
					<input id="confirm-password" name="confirmPassword" placeholder="Confirm Password" type="password" />
					<label htmlFor="password">Confirm Password</label>
				</div>
				<div>
					<span>
						{'By signing up, you agree to the '}
						<a href="https://www.current-lab.com/terms"><b>Terms</b></a>
					</span>
				</div>
			</>}
			<button
				type="submit"
				onClick={
					posthog.capture('Create Account Clicked')
				}
			>
				{newUser ? 'Create Account' : 'Sign In'}
			</button>
		</form>
  	)
}

export const SignUp = () => {

const [newUser, toggleNewUser] = useState(true);
const [showHelper, toggleHelper] = useState(false);
const [errorMessage, setErrorMessage] = useState(null);
const [emailInput, setEmailInput] = useState('');  // new state variable that updates every time email input changes

const signIn = useCallback(async e => {
    
	e.preventDefault()
    const { email, password } = e.target.elements
    const auth = getAuth()

	try {
    await signInWithEmailAndPassword(auth, email.value, password.value)
  } catch (e) {
    console.log(e.message)
	// Provide human-readable error messages
	if(e.code === 'auth/wrong-password'){
		setErrorMessage('Incorrect password.  Please try again or reset your password.')
	}else if(e.code === 'auth/user-not-found'){
		setErrorMessage('User not found.  Please try again or sign up for an account.')
	}else{
		// For other errors, you can still display the default error message
		setErrorMessage(e.message);
	}
	}
}, [])

const signUp = useCallback(async e => {
    
	e.preventDefault()
    const { email, password, confirmPassword } = e.target.elements;
    const auth = getAuth();
	// Check that the 2 passwords match
	if(newUser && password.value !== confirmPassword.value) {
		setErrorMessage("Passwords do not match");
		return;
	} else {
		try {
		await createUserWithEmailAndPassword(auth, email.value, password.value).then(()=>{
					// toggleModal(false);
					console.log('user created');
				})
		} catch (e) {
			console.log(e.code)
			if (e.code == 'auth/email-already-in-use') {
				toggleHelper(true);
				toggleNewUser(false);
				setErrorMessage('This email is already in use');
			} else {
				toggleHelper(false);
				setErrorMessage(e.message);
			}
			console.log(e.code)
		}
	}

}, [])

const forgotPassword = useCallback(async () => {
	if (emailInput) {
	  try {
		const auth = getAuth();
		await sendPasswordResetEmail(auth, emailInput);
		setErrorMessage('Password reset email sent. Check your spam folder if not in inbox.');
		} catch (error) {
			console.log(error);
			if (error.code === 'auth/user-not-found') {
				setErrorMessage('No account found with this email address. Please try again.');
			} else if (error.code === 'auth/invalid-email') {
				setErrorMessage('Invalid email. Please check and try again.');
			} else {
				setErrorMessage('Error: ' + error.message);
			}
		}
	} else {
		setErrorMessage('Please enter your email address.');
	}
  }, [emailInput]);

return (
    <Wrapper>
		<a href='https://www.current-lab.com'><img src={Logo} alt="" /></a>
      	<h1>{newUser ? 'Create your account' : 'Sign in'}</h1>
      	{/* {showHelper ? <p>Looks like you already have an account.</p> : null} */}
		{errorMessage && <p style={{ color: "red" }}><i>{errorMessage}</i></p>}		
		<SignUpForm 
		  newUser={newUser} 
		  onSubmit={newUser ? signUp : signIn} 
		  onForgotPassword={forgotPassword}
		  errorMessage={errorMessage}
		  setEmailInput={setEmailInput}
		/>
		<p>
			<span>{newUser ? 'Already have an account?' : 'Don\'t have a Current Map Account?'}</span>
			&nbsp;
			<b onClick={()=> {
				setErrorMessage(null);  // Clear any error messages
				toggleHelper(false); toggleNewUser(!newUser)}}>{!newUser ? 'Create one!' : 'Click here to Sign In.'
				}
			</b>
			&nbsp;
		</p>
		{!newUser && 
				<p>
					Forgot password? 
					&nbsp;
					<b onClick={forgotPassword}>Click here to reset</b>
				</p>
			}
    </Wrapper>
  )
}