import React from 'react';

function Leave(props) {
	const title = props.title || "Sign out";

	return (
		<svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <title>{title}</title>
            <g fill="#454545" stroke="#454545" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                <line fill="none" x1="11" x2="22" y1="10" y2="10"/>
                <polyline fill="none" points="18 6 22 10 18 14"/>
                <polyline fill="none" points="13 13 13 17 8 17" stroke="#454545"/>
                <polyline fill="none" points="1 2 8 7.016 8 22 1 17 1 2 13 2 13 7" stroke="#454545"/>
            </g>
        </svg>
	);
};

export default Leave;