import React, { useContext, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import RadioEmptyThin from './icons/RadioEmptyThin'
import RadioFilledThin from './icons/RadioFilledThin'

import Radio_Empty from './icons/Radio_Empty'
import Radio_Selected from './icons/Radio_Selected'
import Radio_Hovered from './icons/Radio_Hovered'

import Resolution_Low from './icons/Resolution_Low'
import Resolution_Medium from './icons/Resolution_Medium'
import Resolution_High from './icons/Resolution_High'
import Resolution_Time from './icons/Resolution_Time'
import Info from './icons/Info'
import Unavailable from './icons/Unavailable'
import Download from './icons/Download'
import Loader from './components/modal/Loader'
import ModelPurchase from './components/modal/ModelPurchase';
import { updateFavorite } from './util/updateFavorite';
import { MapLayersContext } from './contexts/MapLayers';
import { WindSpeedContext } from './contexts/WindSpeed';
import { TimeSelectorContext } from './contexts/TimeSelector'
// import useModelAccess from './hooks/useModelAccess';
import useVisible, { intersectsMap, modelArea } from './hooks/useVisible'
import { roundHours } from './util/Rounding'
import posthog from 'posthog-js';
import { getFirestore, collection, addDoc, doc, getDoc, setDoc, getDocs, query, where } from "firebase/firestore"
import { getProducts } from './contexts/getProducts';
import { Button } from '@chakra-ui/react';

const db = getFirestore();

const Wrapper = styled.ul`
    background: white;
    z-index: 10;
    margin: 0;
    padding: 0;
    list-style: none;

    h2 {
        margin: 0;
        padding: 0.5rem 1rem;
        font-size: 12px;
        font-weight: 500;
        position: sticky;
        text-transform: uppercase;
        letter-spacing: 1px;
        top: 0;
        background: #f1f1f1;
        border-bottom: 1px solid #dbdbdb;
    }

`

const Model = styled.li`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 12px;
    padding: 1rem 1.33rem 1rem 1rem;
    cursor: pointer;
    align-items: top;

    &:hover {
        background: rgb(2, 115, 245, 0.05);
        background: #fafafa;
    }

    h3 {
        font-size: 13px;
        margin: 0;
        font-weight: 500;
    }

    & + li {
        border-top: 1px solid #dbdbdb;
    }
    
    & > svg:nth-child(2) {
        height: 16px;
        width: 16px;
        * polyline {
            stroke: #757575;
        }
        * path {
            fill: #0273f5;
        }
        * circle {
            stroke: #757575;
        }
    }
`

const PurchasedModel = styled.li`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 12px;
    padding: 1rem 1.33rem 1rem 1rem;
    cursor: pointer;
    align-items: top;

    &:hover {
        background: rgb(2, 115, 245, 0.05);
    }

    h3 {
        font-size: 13px;
        margin: 0;
        font-weight: 500;
        color: #f08c00;
    }

    & + li {
        border-top: 1px solid #dbdbdb;
    }
    
    & > svg:nth-child(2) {
        height: 16px;
        width: 16px;
        * polyline {
            stroke: #757575;
        }
        * path {
            fill: #0273f5;
        }
        * circle {
            stroke: #757575;
        }
    }
`

const PremiumModel = styled.li`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 12px;
    padding: 1rem 1.33rem 1rem 1rem;
    cursor: pointer;
    align-items: top;
    color: #868e96;

    &:hover {
        background: rgb(2, 115, 245, 0.05);
        background: #fafafa;
    }

    h3 {
        font-size: 13px;
        margin: 0;
        font-weight: 500;
        color: #868e96;
    }

    & + li {
        border-top: 1px solid #dbdbdb;
    }
    
    & > svg:nth-child(2) {
        height: 16px;
        width: 16px;
        * polyline {
            stroke: #868e96;
        }
        * path {
            fill: #868e96;
        }
        * circle {
            stroke: #868e96;
        }
    }
`

const Aspect = styled.ul`
    margin: 0;
    padding: 0;
    font-size: 11px;
    li {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 12px;
        margin: 0;
        padding: 0;
        margin-top: 0.5rem;
        align-items: center;
        svg {
            height: 8px;
            width: 8px;
        }
    }
`

const UpgradeButton = styled.li`
    cursor: pointer;
    height: 2rem;
    padding: 10px 20px;
    border-radius: 4px;
    h3 {
        font-size: 12px;
        margin: auto;
        font-weight: 500;
        color: #495057;
        text-align: center;
    }
    &:hover {
        background: rgb(2, 115, 245, 0.05);
        background: #ced4da;
    }
`

const ResolutionIcon = ({resolution}) => {
    if (resolution <= 0.25) {
        return <Resolution_High/>
    } else if (resolution <= 1) {
        return <Resolution_Medium/>
    } else if (resolution > 1) {
        return <Resolution_Low/>  
    }
}

const partitionTitles = (partitionKey) => {
    switch(partitionKey) {
        case 'withinView':
            return 'Visible Forecast Models';
        case 'outOfView':
            return 'Available Forecast Models';
        case 'expired':
            return 'Unavailable Models';
    }
}

const TextWrapper = styled.span`
    display: block;
    overflow: hidden;
    cursor: ${(props) => props.expanded ? 'n-resize' : 'context-menu'};
`

const ExpandableText = ({text}) => {
    const [expanded, setExpanded] = useState(false);
    return <TextWrapper onClick={(e)=>{setExpanded(!expanded); e.stopPropagation();}} expanded={expanded}>
        {expanded ? text : 'More Info'}
    </TextWrapper>
}

function getFileSize(grib_file) {
    
    if (grib_file == null) {
        return null;
    }

    var fileSize = 0;
    var http = new XMLHttpRequest();
    http.open('HEAD', `https://storage.googleapis.com/currentmap-bucket01/${grib_file}`, false); // false = Synchronous

    http.send(null); // it will stop here until this http request is complete

    // when we are here, we already have a response, b/c we used Synchronous XHR

    if (http.status === 200) {
        fileSize = http.getResponseHeader('content-length');
        console.log('fileSize = ' + fileSize);
    }

    return (fileSize/1000000).toFixed(2);
}

const Layer = ({name, premium, model, expired, timestep, acknowledgment, grib_latest, resolution, area, hovered, selected, onClick, onMouseOver, onMouseOut}) => {
    
    return (
        <Model onClick={!expired ? onClick : undefined} onMouseOver={onMouseOver} onMouseOut={onMouseOut} id={`li-${model}`}>
            <div>
                <h3>{name}</h3>
                <Aspect>
                    <li>
                        <ResolutionIcon resolution={resolution}/>
                        <div><span>{resolution}</span> km Resolution</div>
                    </li>
                    <li>
                        <Resolution_Time/>
                        <div><span>{timestep}</span> Timestep</div>
                    </li>
                    <li>
                        <Info/>
                        <div><ExpandableText text={acknowledgment}></ExpandableText></div>
                    </li>
                    {grib_latest ?
                    <li onClick={(e) => {
                        const filesize = getFileSize(grib_latest);
                        if (window.confirm(`Download ${grib_latest.split('/')[2]} (${filesize} MB)?`)) {
                            window.location = `https://storage.googleapis.com/currentmap-bucket01/${grib_latest}`
                        }
                        e.stopPropagation()
                    }}>
                        <Download/>
                        <div>Download Latest GRIB File</div>
                    </li> : null}
                    {expired && (
                        <li>
                            <Unavailable/>
                            <div>Model Temporarily Offline</div>
                        </li>
                    )}
                </Aspect>
            </div>
            {!expired ? (selected ? <Radio_Selected/> : hovered ? <Radio_Hovered/> : <Radio_Empty/>) : null}
        </Model>
    )
}

const PremiumLayer = ({name, products, userData, user, price, purchaseToggle, setPurchaseToggle, premium, model, expired, timestep, acknowledgment, grib_latest, resolution, area, hovered, selected, onClick, onMouseOver, onMouseOut}) => {
    const [loading, setLoading] = useState(false);

    
    return (
        <PremiumModel onMouseOver={onMouseOver} onMouseOut={onMouseOut} id={`li-${model}`}>
            <div>
                <h3>{`${name} - Premium`}</h3>
                <Aspect>
                    <li>
                        <ResolutionIcon resolution={resolution}/>
                        <div><span>{resolution}</span> km Resolution</div>
                    </li>
                    <li>
                        <Resolution_Time/>
                        <div><span>{timestep}</span> Timestep</div>
                    </li>
                    <li>
                        <Info/>
                        <div><ExpandableText text={acknowledgment}></ExpandableText></div>
                    </li>
                    {/* {grib_latest ?
                    <li>
                        <Download/>
                        <div>Download Latest GRIB File</div>
                    </li> : null} */}
                    {expired && (
                        <li>
                            <Unavailable/>
                            <div>Model Temporarily Offline</div>
                        </li>
                    )}
                </Aspect>
            </div>
            {/* button to learn more */}
            <Button
                size='sm'
                // variant='outline'
                // colorScheme='green'
                onClick={ async (e) => {
                // // window.alert('This model is a premium model. Please upgrade to access this model.')
                // if (price) {
                //     setLoading(true);
                //     setPurchaseToggle(!purchaseToggle);

                //     // await createCheckoutSession(user.uid, price);
                // } else {
                //     window.alert('Please Contact Current Lab to Get Access to this Model');
                // }
                // NOTE: This is a new simpler solution that shows the same Upgrade button for all premium models:
                setLoading(true);
                setPurchaseToggle(!purchaseToggle);
                
            }}>
                <h3>Upgrade</h3>
            </Button>
        </PremiumModel>
    )
}

const PurchasedLayer = ({name, userData, user, premium, model, expired, timestep, acknowledgment, grib_latest, resolution, area, hovered, selected, onClick, onMouseOver, onMouseOut}) => {
    
    return (
        <PurchasedModel onClick={!expired ? onClick : undefined} onMouseOver={onMouseOver} onMouseOut={onMouseOut} id={`li-${model}`}>
            <div>
                <h3>{`${name} - Premium`}</h3>
                <Aspect>
                    <li>
                        <ResolutionIcon resolution={resolution}/>
                        <div><span>{resolution}</span> km Resolution</div>
                    </li>
                    <li>
                        <Resolution_Time/>
                        <div><span>{timestep}</span> Timestep</div>
                    </li>
                    <li>
                        <Info/>
                        <div><ExpandableText text={acknowledgment}></ExpandableText></div>
                    </li>
                    {grib_latest ?
                    <li onClick={(e) => {
                        const filesize = getFileSize(grib_latest);
                        if (window.confirm(`Download ${grib_latest.split('/')[2]} (${filesize} MB)?`)) {
                            window.location = `https://storage.googleapis.com/currentmap-bucket01/${grib_latest}`
                        }
                        e.stopPropagation()
                    }}>
                        <Download/>
                        <div>Download Latest GRIB File</div>
                    </li> : null}
                    {expired && (
                        <li>
                            <Unavailable/>
                            <div>Model Temporarily Offline</div>
                        </li>
                    )}
                </Aspect>
            </div>
            {/* button to learn more */}
            {!expired ? (selected ? <Radio_Selected/> : hovered ? <Radio_Hovered/> : <Radio_Empty/>) : null}
        </PurchasedModel>
    )
}

const LayerToggle = (props) => {
    const { authUser, user, modelAccess, purchaseToggle, setPurchaseToggle } = props;
    const { trackedLayersAlt, trackedLayers, trackLayers, selectedModel, selectModel } = useContext(MapLayersContext);
    const models = useVisible(trackedLayersAlt);
    const [hovered, setHovered] = useState('');
    const { time } = useContext(TimeSelectorContext);
    const [ products, setProducts ] = useState([]);

    useEffect(() => {
        getProducts().then(res => {
            setProducts(res);
        })
    }, [])

    return (
        <Wrapper id="scroller">
            {Object.keys(models).map((partition) => {
                return (
                    <>
                        {models[partition].length ? <h2>{partitionTitles(partition)} ({models[partition].length})</h2> : null}
                        {models[partition].map((layer) => {
                            if (layer.access) {
                                if (layer.purchased) {
                                    return (
                                        <PurchasedLayer
                                            name={layer.layers.name}
                                            model={layer.layers.model}
                                            expired={layer.expired}
                                            resolution={layer.layers.resolution_km}
                                            area={Math.floor(modelArea(layer)/1000000).toLocaleString()}
                                            timestep={layer.layers.timestep}
                                            acknowledgment={layer.acknowledgment}
                                            grib_latest={layer.grib_latest}
                                            selected={layer.layers.model == selectedModel}
                                            hovered={hovered == layer.layers.model}
                                            onClick={() => {
                                                if (user?.models?.includes(layer.layers.model) || layer.access) {
                                                    selectModel(layer.layers.model)
                                                    updateFavorite(authUser?.uid, layer.layers.model );
                                                }
                                                posthog.capture('Model Selected', {
                                                    'Model': layer.layers.model,
                                                    'Time': time
                                                })
                                            }}
                                            onMouseOver={()=>setHovered(layer.layers.model)}
                                            onMouseOut={()=>setHovered('')}
                                        />
                                    )
                                } else {
                                    return (
                                    <Layer
                                        name={layer.layers.name}
                                        model={layer.layers.model}
                                        expired={layer.expired}
                                        resolution={layer.layers.resolution_km}
                                        area={Math.floor(modelArea(layer)/1000000).toLocaleString()}
                                        timestep={layer.layers.timestep}
                                        acknowledgment={layer.acknowledgment}
                                        grib_latest={layer.grib_latest}
                                        selected={layer.layers.model == selectedModel}
                                        hovered={hovered == layer.layers.model}
                                        onClick={() => {
                                            if (user?.models?.includes(layer.layers.model) || layer.access) {
                                                selectModel(layer.layers.model)
                                                updateFavorite(authUser?.uid, layer.layers.model );
                                            }
                                            posthog.capture('Model Selected', {
                                                'Model': layer.layers.model,
                                                'Time': time
                                            })
                                        }}
                                        onMouseOver={()=>setHovered(layer.layers.model)}
                                        onMouseOut={()=>setHovered('')}
                                    />
                                    )
                                }
                            } else {
                                return (
                                    <PremiumLayer
                                        name={layer.layers.name}
                                        purchaseToggle={purchaseToggle}
                                        setPurchaseToggle={setPurchaseToggle}
                                        price={layer.price}
                                        products={products}
                                        user={authUser}
                                        userData={user}
                                        model={layer.layers.model}
                                        expired={layer.expired}
                                        resolution={layer.layers.resolution_km}
                                        area={Math.floor(modelArea(layer)/1000000).toLocaleString()}
                                        timestep={layer.layers.timestep}
                                        acknowledgment={layer.acknowledgment}
                                        grib_latest={layer.grib_latest}
                                        selected={layer.layers.model == selectedModel}
                                        hovered={hovered == layer.layers.model}
                                        onClick={()=>{
                                            // selectModel(layer.layers.model)
                                        }}
                                        onMouseOver={()=>setHovered(layer.layers.model)}
                                        onMouseOut={()=>setHovered('')}
                                    />
                                )
                            }
                        })}
                    </>
                )
            })}
        </Wrapper>
    )
}

export {LayerToggle}